body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.app-container {
  height: 100%;
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-header {
  width: 100%;
  display: flex;
  background: #1b1c1d;
  padding: 0.5em 1em;
}

.main-header-title {
  flex-grow: 2;
  display: flex;
  align-items: center;
}

.main-header-title h2 {
  color: #eeeeee;
  font-size: 1.3em;
}

.main-header .ui.inverted.segment {
  border-radius: 0px;
  margin: 0px;
  flex-grow: 1;
  display: flex;
  justify-content: right;
}

.ui.menu .item > i.icon {
  margin: 0px;
  font-size: 18px;
}

.Footer {
  width: 100%;
  padding: 0.5em 1em;
  background: #1b1c1d;
  color: #eeeeee;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0em;
}

.footer-wrapper h4 {
  font-size: 16px;
  margin: 0em;
}

.ui.negative.message {
  margin-top: 30%;
  font-size: 18px;
  line-height: 1.4em;
  padding: 1.5em 1em;
}

.ui.negative.message .header {
  font-size: 26px;
  line-height: 1.4em;
}

.Home,
.About,
.NewGrocery,
.PaymentDetails,
.EditPaymentDetail,
.ResultPayments,
.Login {
  flex-grow: 1;
  width: 100%;
  padding: 0.5em;
  overflow: auto;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("./files/background-image.jpg");
  background-repeat: repeat;
  background-size: 100%;
  background-position: center;
  border: 1px solid #d8d8d8;
}

.summary-for-each-person-container {
  width: 100%;
  box-shadow: 0px 0px 5px#1b1c1d50;
  border-radius: 5px;
  margin: 0.5em 0em;
}

.each-person-payment-container {
  display: flex;
  background-color: #ffffffd4;
  border-radius: 5px;
}

.person-profile-image-container {
  width: 30%;
  padding: 1em;
}

.person-profile-image-container img {
  width: 100%;
  margin-top: 5px;
  border-radius: 50%;
}

.person-profile-payment-container {
  width: 70%;
  padding: 1.5em 0.5em;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.person-profile-payment-body {
  flex-grow: 1;
}

.person-profile-payment-body h3 {
  margin: 0px;
  line-height: 1.4em;
  display: flex;
  font-size: 24px;
}

.person-profile-payment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.person-profile-payment-footer h3 {
  margin: 0px;
  font-size: 14px;
}

.calculate-button-container {
  text-align: center;
}
/* Filter Function */
.filter-function-component-container {
  display: flex;
  justify-content: space-between;
}

.each-filter-container {
  width: 48%;
}

.each-filter-container h3,
.payment-details .each-filter-container h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: center;
}

.filter-dropdown {
  width: 100%;
}

/* Add New Grocery Page CSS */
.add-new-grocery-container {
  padding: 15vh 0em 0em;
}

.add-new-grocery {
  background-color: #ffffffd4;
  box-shadow: 0px 0px 5px#1b1c1d50;
  border-radius: 5px;
  padding: 1em;
  text-align: center;
}

.add-new-grocery h3 {
  font-size: 22px;
  margin-top: 0.5em;
}

.add-new-grocery h4 {
  margin: 0.5em 0;
  color: green;
}

.add-new-grocery .new-grocery-amount-container {
  width: 100%;
  margin: 1em 0px;
}

.date-picker-container {
  margin-bottom: 1em;
}

.date-picker-container .field,
.date-picker-container .field .input {
  width: 100%;
}

.date-picker-container .ui.icon.input > input {
  cursor: pointer;
}

.add-new-grocery .payment-made-by {
  margin-top: 1em;
  width: 100%;
  padding: 1em 0px;
  text-align: right;
  font-size: 16px;
}

.ui.dropdown > .text > .image,
.ui.dropdown > .text > img {
  margin-top: -0.25em;
}

.add-new-grocery .button {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Payment Details  CSS */

.payment-details-container {
  padding: 0em;
}

.payment-details-container h3 {
  text-align: center;
  font-size: 26px;
  margin: 1em 0em;
}

.payment-detail-image {
  text-align: center;
}

.payment-detail-image img {
  width: 30%;
  border-radius: 50%;
}

.payment-detail-body h4 {
  text-align: center;
  font-size: 22px;
  margin-top: 1em;
}

.ui.card.payment-details {
  background: #ffffffbf;
}

.ui.card > .content.payment-records-content-container {
  max-height: 40vh;
  overflow-y: auto;
}

.payment-records-content-header {
  text-align: center !important;
  padding: 0.2em 0em;
}

.payment-detail-button {
  text-align: center;
}

.payment-records-feed-summary-container {
  display: flex;
  justify-content: space-between;
}

.payment-records-feed-editor {
  display: flex;
  align-items: center;
}

.payment-records-feed-editor i {
  font-size: 20px;
  margin: 0em 5px !important;
}

.payment-records-feed-summary p {
  margin: 0em !important;
  font-weight: 500;
}

/* Calculate Payments */
.ResultPayments {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculate-payment-container {
  background-color: #ffffffd4;
  padding: 2em 1em;
  border-radius: 5px;
  box-shadow: 0px 0px 5px#1b1c1d50;
  width: 100%;
}

.ui.card,
.ui.cards > .card {
  width: 100%;
}

.payment-summary-header {
  text-align: center;
}

.payment-calculation {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
}

.payment-calculation .content h4 {
  text-align: center;
  font-size: 20px;
}

.payment-calculation .summary h5 {
  font-size: 18px;
}

p.summary-footer {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

/* Login Page */
.login-component {
  text-align: center;
}

.login-component-container {
  padding-top: 4em;
}

.require-safe-work-container {
  padding: 2em 0;
}

.require-safe-work-container img {
  width: 70%;
}

.safe-word-input-container h2 {
  margin-bottom: 1em;
}

.safe-word-input-container .input {
  width: 80%;
}

.safe-word-input-container .button {
  margin-top: 2em;
}
